* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #202022;
  color: #cacaca;
}

main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

header {
  position: fixed;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 20px;
  background-color: #00747c;
  z-index: 5;
}

#logo-div {
  background-color: red;
}

#tj-logo {
  border: 2px solid #202022;
  height: 80px;
  fill: #202022;
  margin: 5px;
}

h2 {
  color: #00bbc9;
}

section {
  margin-top: 20px;
  width: 90%;
  border: 1px solid #00747c;
  border-radius: 5px;
  padding: 20px;
}

.nav-button {
  padding: 15px;
  border-radius: 5px;
}

.nav-button:hover {
  background-color: #00bbc9;
}

.menu-icon {
  width: 40px;
  height: 40px;
  background-color: #00747c;
  fill: #cacaca;
}

.menu {
  position: absolute;
  background-color: #00747c;
  padding: 5px 10px;
  border: 1px solid #202022;
  border-radius: 3px;
  right: 30px;
}

#hero-section {
  margin-top: 100px;
  max-width: 900px;
}

.spacer {
  height: 15px;
  background-color: #00bbc9;
}

img {
  border-radius: 3px;
  opacity: 0.65;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}

a {
  text-decoration: none;
  /* color: #cacaca; */
  color: #00747c;
}

.project-container:hover > .project-img-container > .project-img {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(103%);
  transform: scale(103%);
  border: 3px solid #00747c;
  -webkit-transition: opacity ease-out 250ms;
  transition: opacity ease-out 250ms;
  -webkit-transition: -webkit-filter ease-out 250ms;
  transition: -webkit-filter ease-out 250ms;
  transition: filter ease-out 250ms;
  transition: filter ease-out 250ms, -webkit-filter ease-out 250ms;
  -webkit-transition: -webkit-transform ease-out 250ms;
  transition: -webkit-transform ease-out 250ms;
  transition: transform ease-out 250ms;
  transition: transform ease-out 250ms, -webkit-transform ease-out 250ms;
}

#about-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-text {
  width: 75%;
  padding: 10px;
}

.profile-img-container {
  border-radius: 3px;
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  background: #00747c;
  margin: 20px;
}

.profile-img {
  opacity: 0.9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.projects-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.project-container {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.project-container .title-box,
.project-container .description-box {
  text-align: right;
}

.project-container .project-link-container {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.project-container:nth-child(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.project-container:nth-child(odd) .title-box,
.project-container:nth-child(odd) .description-box {
  text-align: left;
}

.project-container:nth-child(odd) .project-link-container {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.project-img-container {
  margin: 5px;
  border-radius: 3px;
  position: relative;
  background: #00747c;
  width: 55%;
  height: 300px;
}

.project-img {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left;
  object-position: center;
  height: 100%;
  width: 100%;
}

.project-info {
  width: 43%;
  z-index: 3;
}

.title-box {
  color: #00bbc9;
  font-size: 1.5rem;
  border-radius: 3px;
  padding: 5px 20px 1px 10px;
  margin: 5px;
}

.description-box {
  padding: 5px 15px;
  background: #00747c;
  color: #cacaca;
  border-radius: 3px;
}

.tech-stack-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 5px;
}

.project-link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.project-link {
  fill: #00bbc9;
  margin: 10px;
  width: 30px;
}

.project-link:hover {
  fill: #cacaca;
}

#contact {
  margin-bottom: 30px;
}

.link-container {
  padding: 10px;
  font-size: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

#contact a {
  fill: #00bbc9;
  width: 20%;
  max-width: 80px;
  color: #00bbc9;
}

#contact a:hover {
  fill: #cacaca;
  color: #cacaca;
}

@media (max-width: 1200px) {
  #about-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .profile-img-container {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .nav-xs {
    display: initial;
  }
  .nav-sm {
    display: none;
  }
  .about-text {
    width: 95%;
  }
  .project-container,
  .project-container:nth-child(odd) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .profile-img-container,
  .project-img-container,
  .project-info,
  .project-spacer {
    width: 100%;
  }
  #hero-section {
    font-size: 10px;
  }
}

@media (min-width: 600px) {
  .nav-xs {
    display: none;
  }
  .nav-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
